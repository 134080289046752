import ChampsDuFormulaireDemarche from "../GestionnaireDeDemarche/types/ChampsDuFormulaireDemarche"
import Validation from "./validateursParChamp/Validation"
import NomDeFamilleValidation from "./validateursParChamp/NomDeFamilleValidation"
import NomDUsageValidation from "./validateursParChamp/NomDUsageValidation"
import SexeValidation from "./validateursParChamp/SexeValidation"
import PrenomValidation from "./validateursParChamp/PrenomValidation"
import PrenomSecondaireValidation from "./validateursParChamp/PrenomSecondaireValidation"
import NationaliteValidation from "./validateursParChamp/NationaliteValidation"
import LieuDInscriptionValidation from "./validateursParChamp/LieuDInscriptionValidation"
import DateDeNaissanceCompleteValidation from "./validateursParChamp/DateDeNaissanceCompleteValidation"
import DateDeNaissanceIncompleteValidation from "./validateursParChamp/DateDeNaissanceIncompleteValidation"
import PaysDeNaissanceValidation from "./validateursParChamp/PaysDeNaissanceValidation"
import ListeDElectionEuropeenneValidation from "./validateursParChamp/ListeDElectionEuropeenneValidation"
import PieceJustificativeValidation from "./validateursParChamp/PieceJustificativeValidation"
import PieceJustificativeDeclarationEcriteValidation
	from "./validateursParChamp/PieceJustificativeDeclarationEcriteValidation"
import AdresseEmailValidation from "./validateursParChamp/AdresseEmailValidation"
import NumeroTelephoneValidation from "./validateursParChamp/NumeroTelephoneValidation"
import CommuneDeNaissanceAvecNumeroDepartementValidation
	from "./validateursParChamp/CommuneDeNaissanceAvecNumeroDepartementValidation"
import AdresseRedresseeValidation from "./validateursParChamp/AdresseRedresseeValidation"
import NumeroDeVoieValidation from "./validateursParChamp/NumeroDeVoieValidation"
import CommuneDeResidenceValidation from "./validateursParChamp/CommuneDeResidenceValidation"
import CommuneDeResidenceEtrangerValidation from "./validateursParChamp/CommuneDeResidenceEtrangerValidation"
import CodePostalDeResidenceValidation from "./validateursParChamp/CodePostalDeResidenceValidation"
import Complement1AdresseValidation from "./validateursParChamp/Complement1AdresseValidation"
import Complement2AdresseValidation from "./validateursParChamp/Complement2AdresseValidation"
import LieuDitLibelleDeVoieValidation from "./validateursParChamp/LieuDitLibelleDeVoieValidation"
import ValidationMultiChamps from "./validateursParChamp/ValidationMultiChamps"
import ChoixTypeJustificatifValidation from "./validateursParChamp/ChoixTypeJustificatifValidation"
import ChangementCommuneValidation from "./validateursParChamp/ChangementCommuneValidation"
import CodePostalDeResidenceEtrangerValidation from "./validateursParChamp/CodePostalDeResidenceEtrangerValidation"
import PaysValidation from "./validateursParChamp/PaysValidation"

export type ValidateurDeDemarcheInterface = {
	valider: (valeurs: ChampsDuFormulaireDemarche, oidcLogin: boolean, rnvpOK?: boolean) => ErreursDuFormulaireDemarche
}

const ValidateurDeDemarche = (validateursParChamps: Validateurs): ValidateurDeDemarcheInterface => {
	return {
		valider: (valeurs: ChampsDuFormulaireDemarche, oidcLogin: boolean, rnvpOK = true): ErreursDuFormulaireDemarche => {
			const champsEnErreur: ErreursDuFormulaireDemarche = {}
			const champsQuiPeuventEtreValidesUnitairement = [
				"nomDeFamille",
				"nomDUsage",
				"sexe",
				"prenom2",
				"prenom3",
				"nationalite",
				"lieuDInscription",
				"paysDeNaissance",
				"communeDeNaissance",
				"adresseEmail",
				"numeroTelephone",
				"choixTypeJustificatif",
				"unSeulFichierPJ",
				"changementCommune"
			]

			for (const nom in validateursParChamps) {
				if(champsQuiPeuventEtreValidesUnitairement.includes(nom)){
					const clé = nom as keyof ChampsÀValiderUnitairement
					const validateur = validateursParChamps[clé]
					if (validateur && !validateur.estValide(valeurs[clé])) {
						champsEnErreur[clé] = validateur.messageErreur(valeurs[clé])
					}
				}
			}

			if (!valeurs.pasDePrenom) {
				const validationPrenom = validateursParChamps["prenom"]
				if (!validationPrenom.estValide(valeurs.prenom)) {
					champsEnErreur["prenom"] = validationPrenom.messageErreur(valeurs.prenom)
				}
			}

			if (!oidcLogin && valeurs.usagerAUneDateDeNaissanceIncomplète && valeurs.dateDeNaissanceIncomplète != undefined) {
				const validationDeDateIncomplete =  validateursParChamps["dateDeNaissanceIncomplete"]
				if (!validationDeDateIncomplete.estValide(valeurs.dateDeNaissanceIncomplète))
					champsEnErreur["dateDeNaissanceIncomplète"] = validationDeDateIncomplete.messageErreur(valeurs.dateDeNaissanceIncomplète)
			}

			if ((oidcLogin || !valeurs.usagerAUneDateDeNaissanceIncomplète) && valeurs.dateDeNaissance != undefined ) {
				const validationDeDate = validateursParChamps["dateDeNaissance"]
				if (!validationDeDate.estValide(valeurs.dateDeNaissance)) {
					champsEnErreur["dateDeNaissance"] = validationDeDate.messageErreur(valeurs.dateDeNaissance)
				}
			}

			if(valeurs.nationalite !== "FRA"){
				const validationTypeDeListe = validateursParChamps["typeDeListe"]
				if (!validationTypeDeListe.estValide(valeurs.typeDeListe)) {
					champsEnErreur["typeDeListe"] = validationTypeDeListe.messageErreur(valeurs.typeDeListe)
				}
				const validationDeDeclarationEcrite = validateursParChamps["idDeclarationEcrite"]
				if (!validationDeDeclarationEcrite.estValide(valeurs.idDeclarationEcrite)) {
					champsEnErreur["idDeclarationEcrite"] = validationDeDeclarationEcrite.messageErreur(valeurs.idDeclarationEcrite)
				}
			}

			const validationidPieceJustificative = validateursParChamps["idJustificatifIdentite"]
			if (!validationidPieceJustificative.estValide([valeurs.idJustificatifIdentite, valeurs.nomJustificatifIdentite])){
				champsEnErreur["idJustificatifIdentite"] = validationidPieceJustificative.messageErreur([valeurs.idJustificatifIdentite, valeurs.nomJustificatifIdentite])
			}

			const validationidPieceJustificative2 = validateursParChamps["idJustificatifIdentite2"]
			if (!validationidPieceJustificative2.estValide([valeurs.idJustificatifIdentite2, valeurs.nomJustificatifIdentite2]) && !valeurs.unSeulFichierPJ && valeurs.choixTypeJustificatif !== "passeport"){
				champsEnErreur["idJustificatifIdentite2"] = validationidPieceJustificative.messageErreur([valeurs.idJustificatifIdentite2, valeurs.nomJustificatifIdentite2])
			}

			const validationIdJustificatifDomicile = validateursParChamps["idJustificatifDomicile"]
			if (!validationIdJustificatifDomicile.estValide([valeurs.idJustificatifDomicile, valeurs.nomJustificatifDomicile])){
				champsEnErreur["idJustificatifDomicile"] = validationIdJustificatifDomicile.messageErreur([valeurs.idJustificatifDomicile, valeurs.nomJustificatifDomicile])
			}

			const validationIdJustificatifDomicile2 = validateursParChamps["idJustificatifDomicile2"]
			if (!validationIdJustificatifDomicile2.estValide([valeurs.idJustificatifDomicile2, valeurs.nomJustificatifDomicile2]) && !valeurs.unSeulFichierPJDomicile ){
				champsEnErreur["idJustificatifDomicile2"] = validationIdJustificatifDomicile.messageErreur([valeurs.idJustificatifDomicile2, valeurs.nomJustificatifDomicile2])
			}

			if(valeurs.adresseDeRattachementUsagerRemplitSonAdresseManuellement || !rnvpOK){
				const validationNumeroAdresse =  validateursParChamps["numero"]
				const validationCodePostal =  validateursParChamps["codePostal"]
				const validationCommune =  validateursParChamps["commune"]
				const validationComplement1Adresse =  validateursParChamps["complement1Adresse"]
				const validationComplement2Adresse =  validateursParChamps["complement2Adresse"]
				if (!validationNumeroAdresse.estValide(valeurs.adresseDeRattachementNumero))
					champsEnErreur["adresseDeRattachementNumero"] = validationNumeroAdresse.messageErreur(valeurs.adresseDeRattachementNumero)
				if (!validationCodePostal.estValide(valeurs.adresseDeRattachementCodePostal))
					champsEnErreur["adresseDeRattachementCodePostal"] = validationCodePostal.messageErreur(valeurs.adresseDeRattachementCodePostal)
				if (!validationCommune.estValide(valeurs.adresseDeRattachementCommune))
					champsEnErreur["adresseDeRattachementCommune"] = validationCommune.messageErreur(valeurs.adresseDeRattachementCommune)
				if (!validateursParChamps["lieuDitLibelleDeVoie"].estValide([valeurs.adresseDeRattachementLibelleDeVoie, valeurs.adresseDeRattachementLieuDit, valeurs.adresseDeRattachementPays])){
					champsEnErreur["adresseDeRattachementLieuDit"] = validateursParChamps["lieuDitLibelleDeVoie"].messageErreur([valeurs.adresseDeRattachementLibelleDeVoie, valeurs.adresseDeRattachementLieuDit, valeurs.adresseDeRattachementPays]).lieuDit
					champsEnErreur["adresseDeRattachementLibelleDeVoie"] = validateursParChamps["lieuDitLibelleDeVoie"].messageErreur([valeurs.adresseDeRattachementLibelleDeVoie, valeurs.adresseDeRattachementLieuDit, valeurs.adresseDeRattachementPays]).libelleDeVoie
				}
				if (!validationComplement1Adresse.estValide(valeurs.adresseDeRattachementComplement1Adresse))
					champsEnErreur["adresseDeRattachementComplement1Adresse"] = validationComplement1Adresse.messageErreur(valeurs.adresseDeRattachementComplement1Adresse)
				if (!validationComplement2Adresse.estValide(valeurs.adresseDeRattachementComplement2Adresse))
					champsEnErreur["adresseDeRattachementComplement2Adresse"] = validationComplement2Adresse.messageErreur(valeurs.adresseDeRattachementComplement2Adresse)
			} else {
				const validationAdresseRedressee =  validateursParChamps["adresseRedressee"]
				if (!validationAdresseRedressee.estValide(valeurs.adresseDeRattachementAdresseRedressee))
					champsEnErreur["adresseDeRattachementAdresseRedressee"] = validationAdresseRedressee.messageErreur(valeurs.adresseDeRattachementAdresseRedressee)
			}

			if(valeurs.adresseIdentiquePourProgrammeCandidats === "Non") {
				if(valeurs.adresseDeContactUsagerRemplitSonAdresseManuellement || !rnvpOK || (valeurs.adresseDeContactPays !== "FRANCE")) {
					const validationPays =  validateursParChamps["pays"]
					const validationNumeroAdresse =  validateursParChamps["numero"]
					const validationCodePostal =  validateursParChamps["codePostal"]
					const validationCodePostalEtranger =  validateursParChamps["codePostalEtranger"]
					const validationCommune =  validateursParChamps["commune"]
					const validationCommuneEtranger =  validateursParChamps["communeEtranger"]
					const validationComplement1Adresse =  validateursParChamps["complement1Adresse"]
					const validationComplement2Adresse =  validateursParChamps["complement2Adresse"]
					if (!validationNumeroAdresse.estValide(valeurs.adresseDeContactNumero))
						champsEnErreur["adresseDeContactNumero"] = validationNumeroAdresse.messageErreur(valeurs.adresseDeContactNumero)
					if (valeurs.adresseDeContactPays === "FRANCE") {
						if (!validationCodePostal.estValide(valeurs.adresseDeContactCodePostal))
							champsEnErreur["adresseDeContactCodePostal"] = validationCodePostal.messageErreur(valeurs.adresseDeContactCodePostal)
						if (!validationCommune.estValide(valeurs.adresseDeContactCommune))
							champsEnErreur["adresseDeContactCommune"] = validationCommune.messageErreur(valeurs.adresseDeContactCommune)
					} else {
						if (!validationPays.estValide(valeurs.adresseDeContactPays))
							champsEnErreur["adresseDeContactPays"] = validationPays.messageErreur(valeurs.adresseDeContactPays)
						if (!validationCodePostalEtranger.estValide(valeurs.adresseDeContactCodePostal))
							champsEnErreur["adresseDeContactCodePostal"] = validationCodePostalEtranger.messageErreur(valeurs.adresseDeContactCodePostal)
						if (!validationCommuneEtranger.estValide(valeurs.adresseDeContactCommune))
							champsEnErreur["adresseDeContactCommune"] = validationCommuneEtranger.messageErreur(valeurs.adresseDeContactCommune)
					}
					if (!validateursParChamps["lieuDitLibelleDeVoie"].estValide([valeurs.adresseDeContactLibelleDeVoie, valeurs.adresseDeContactLieuDit, valeurs.adresseDeContactPays])){
						champsEnErreur["adresseDeContactLieuDit"] = validateursParChamps["lieuDitLibelleDeVoie"].messageErreur([valeurs.adresseDeContactLibelleDeVoie, valeurs.adresseDeContactLieuDit, valeurs.adresseDeContactPays]).lieuDit
						champsEnErreur["adresseDeContactLibelleDeVoie"] = validateursParChamps["lieuDitLibelleDeVoie"].messageErreur([valeurs.adresseDeContactLibelleDeVoie, valeurs.adresseDeContactLieuDit, valeurs.adresseDeContactPays]).libelleDeVoie
					}
					if (!validationComplement1Adresse.estValide(valeurs.adresseDeContactComplement1Adresse))
						champsEnErreur["adresseDeContactComplement1Adresse"] = validationComplement1Adresse.messageErreur(valeurs.adresseDeContactComplement1Adresse)
					if (!validationComplement2Adresse.estValide(valeurs.adresseDeContactComplement2Adresse))
						champsEnErreur["adresseDeContactComplement2Adresse"] = validationComplement2Adresse.messageErreur(valeurs.adresseDeContactComplement2Adresse)
				} else {
					const validationAdresseRedressee =  validateursParChamps["adresseRedressee"]
					if (!validationAdresseRedressee.estValide(valeurs.adresseDeContactAdresseRedressee))
						champsEnErreur["adresseDeContactAdresseRedressee"] = validationAdresseRedressee.messageErreur(valeurs.adresseDeContactAdresseRedressee)
				}
			}

			return champsEnErreur
		}
	}
}

export const validateursParChamps: Validateurs = {
	nomDeFamille: new NomDeFamilleValidation(),
	nomDUsage: new NomDUsageValidation(),
	sexe: new SexeValidation(),
	prenom: new PrenomValidation(),
	prenom2: new PrenomSecondaireValidation(),
	prenom3: new PrenomSecondaireValidation(),
	nationalite: new NationaliteValidation(),
	lieuDInscription: new LieuDInscriptionValidation(),
	dateDeNaissance: new DateDeNaissanceCompleteValidation(),
	dateDeNaissanceIncomplete: new DateDeNaissanceIncompleteValidation(),
	paysDeNaissance: new PaysDeNaissanceValidation(),
	typeDeListe: new ListeDElectionEuropeenneValidation(),
	communeDeNaissance: new CommuneDeNaissanceAvecNumeroDepartementValidation(),
	adresseRedressee: new AdresseRedresseeValidation(),
	numero: new NumeroDeVoieValidation(),
	commune: new CommuneDeResidenceValidation(),
	communeEtranger: new CommuneDeResidenceEtrangerValidation(),
	pays: new PaysValidation(),
	codePostal: new CodePostalDeResidenceValidation(),
	codePostalEtranger: new CodePostalDeResidenceEtrangerValidation(),
	complement1Adresse: new Complement1AdresseValidation(),
	complement2Adresse: new Complement2AdresseValidation(),
	lieuDitLibelleDeVoie: new LieuDitLibelleDeVoieValidation(),
	idJustificatifIdentite: new PieceJustificativeValidation(),
	idJustificatifIdentite2: new PieceJustificativeValidation(),
	idJustificatifDomicile: new PieceJustificativeValidation(),
	idJustificatifDomicile2: new PieceJustificativeValidation(),
	idDeclarationEcrite: new PieceJustificativeDeclarationEcriteValidation(),
	adresseEmail: new AdresseEmailValidation(),
	numeroTelephone: new NumeroTelephoneValidation(),
	choixTypeJustificatif: new ChoixTypeJustificatifValidation(),
	changementCommune: new ChangementCommuneValidation()
}

type ChampsÀValiderUnitairement = {
	nomDeFamille: string
	nomDUsage: string
	sexe: string
	prenom: string
	prenom2?: string
	prenom3?: string
	nationalite: string
	lieuDInscription: string
	paysDeNaissance: string
	communeDeNaissance: string
	adresseEmail: string
	numeroTelephone: string
	choixTypeJustificatif: string
	changementCommune: string
}

export type Validateurs = {
	nomDeFamille: Validation,
	nomDUsage: Validation,
	sexe: Validation,
	prenom: Validation,
	prenom2: Validation,
	prenom3: Validation,
	nationalite: Validation,
	lieuDInscription: Validation,
	dateDeNaissance: Validation,
	dateDeNaissanceIncomplete: Validation,
	typeDeListe: Validation,
	paysDeNaissance: Validation,
	communeDeNaissance: Validation,
	adresseRedressee: Validation,
	numero: Validation,
	commune: Validation,
	communeEtranger: Validation,
	pays: Validation,
	codePostal: Validation,
	codePostalEtranger: Validation,
	complement1Adresse: Validation,
	complement2Adresse: Validation,
	lieuDitLibelleDeVoie: ValidationMultiChamps,
	idJustificatifIdentite: Validation,
	idJustificatifIdentite2: Validation,
	idJustificatifDomicile: Validation,
	idJustificatifDomicile2:Validation,
	idDeclarationEcrite: Validation,
	adresseEmail: Validation,
	numeroTelephone: Validation,
	choixTypeJustificatif:Validation,
	changementCommune:Validation
}

export type ErreursDuFormulaireDemarche = Partial<ChampsDuFormulaireDemarche>

export default ValidateurDeDemarche
