import React from "react"

const SurPiedDePage: React.FunctionComponent = () => {
	return (
		<div className="fr-footer__top">
			<div className="fr-container">
				<div className="fr-grid-row fr-grid-row--start fr-grid-row--gutters">
					<div className="fr-col-xs-12 fr-col-sm-6 fr-col-md-4">
        	            <h3 className="fr-footer__top-cat">Besoin d’aide pour ce service en ligne&nbsp;?</h3>
						<ul className="fr-footer__top-list">
							<li>
								<a 
									className="fr-footer__top-link" 
									href="https://www.service-public.fr/particuliers/vosdroits/R16396/signaler-un-probleme"
								>
									Avez-vous une question ou un problème&nbsp;?
								</a>
							</li>
							<li>
								<a 
									className="fr-footer__top-link" 
									href="https://www.service-public.fr/particuliers/vosdroits/N47#1"
								>
									Toute l’information sur cette démarche
								</a>
							</li>
						</ul>
					</div>
					<div className="fr-col-xs-12 fr-col-sm-6 fr-col-md-4">
        	            <h3 className="fr-footer__top-cat">Nos engagements</h3>
						<ul className="fr-footer__top-list">
							<li>
								<a
									className="fr-footer__top-link"
									href="https://www.service-public.fr/P10125"
								>
									Accessibilité de la démarche : partiellement conforme
								</a>
							</li>
							<li>
								<a
									className="fr-footer__top-link"
									href="https://www.service-public.fr/P10129"
								>
									Données personnelles et sécurité de la démarche
								</a>
							</li>
							<li>
								<a
									className="fr-footer__top-link"
									href="https://www.service-public.fr/P10128"
								>
									Conditions générales d&rsquo;utilisation de la démarche
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	)
}

export default SurPiedDePage
