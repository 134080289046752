import React, { useState } from "react"
import { ChampsBoutonsRadioProps } from "../ChampBoutonsRadios/ChampBoutonsRadios"
import Validation from "../../../services/ValidateurDeDemarche/validateursParChamp/Validation"
import BoutonRadio, { BoutonRadioProps } from "../BoutonRadio/BoutonRadio"

import "./ChampBoutonsRadiosAvecValidation.css"

interface ChampsBoutonsRadioAvecValidationProps extends ChampsBoutonsRadioProps {
	validation: Validation
	afficherBoutonsRadiosEnLigne: boolean
	required: boolean
	idMessageErreur: string
	messageErreur: string | undefined
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
	disabled?: boolean
}

const ChampBoutonsRadiosAvecValidation: React.FunctionComponent<ChampsBoutonsRadioAvecValidationProps> = ({ legende, boutonsRadio, valeurCochee = "", messageErreur = "", idMessageErreur, validation, afficherBoutonsRadiosEnLigne, required, disabled = false }: ChampsBoutonsRadioAvecValidationProps) => {
	const [aEteModifie, setAEteModifie] = useState<boolean>(false)

	const [messageErreurValidation, setMessageErreurValidation] = useState<string>("")
	const messageErreurAffiche = aEteModifie ? messageErreurValidation : messageErreur
	const messageErreurPresent = messageErreurAffiche !== ""
	const classNameFieldSetEnErreur = messageErreurPresent ? "fr-fieldset--error" : ""
	const classNameInLine = afficherBoutonsRadiosEnLigne ? "fr-fieldset__element--inline" : ""

	function verifierValeur(event: React.ChangeEvent<HTMLInputElement>) {
		setAEteModifie(true)
		if (validation) {
			if (validation.estValide(event.target.value)) {
				setMessageErreurValidation("")
			} else {
				setMessageErreurValidation(validation.messageErreur(event.target.value))
			}
		}
		const boutonsRadioClické:BoutonRadioProps | undefined = boutonsRadio.find(boutonRadio => boutonRadio.valeur === event.target.value)
		if (boutonsRadioClické) {
			boutonsRadioClické.onChange(event)
		}
	}

	return (
		<div className="fr-form-group">
			<fieldset
				className={ "fr-fieldset" + " " + classNameFieldSetEnErreur }
			>
				<legend className="fr-fieldset__legend">
					<h3 className="fr-text--lg fr-mb-0 fr-mt-4w">{ legende }</h3>
				</legend>
				{ boutonsRadio.map(boutonRadio => {
					return (
						<div 
							className={ "fr-fieldset__element " + classNameInLine }
							key={ boutonRadio.id }>
							<BoutonRadio
								checked={ valeurCochee === boutonRadio.valeur }
								disabled={ disabled }
								id={ boutonRadio.id }
								idMessageErreur={ boutonRadio.idMessageErreur }
								key={ boutonRadio.id }
								label={ boutonRadio.label }
								messageChangementDeContexte={ boutonRadio.messageChangementDeContexte }
								nom={ boutonRadio.nom }
								onChange={ verifierValeur }
								required={ required }
								valeur={ boutonRadio.valeur }
							/>
						</div>)
				})  }
				{ messageErreurPresent && 
				<p
					className="fr-error-text"
					id={ idMessageErreur }
					role="alert">
					{ messageErreurAffiche }
				</p> }
			</fieldset>
		</div>
	)
}

export default ChampBoutonsRadiosAvecValidation
