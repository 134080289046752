import React, { ChangeEvent, useState } from "react"
import classNames from "classnames"
import Validation from "../../../services/ValidateurDeDemarche/validateursParChamp/Validation"
export type Option = { label: string, valeur: string }

export type ChampListeDeroulanteProps = {
	ariaDescribedBy?: string,
	ariaLabelledBy?: string
	autoComplete?: string,
	id: string,
	label: string,
	name: string,
	messageErreurFormulaire?: string,
	options: Option[],
	required: boolean
	validation?: Validation
	valeurInitiale: string
	modifierChoix: (nationalite : string) => void,
}

const ChampListeDeroulante: React.FunctionComponent<ChampListeDeroulanteProps> = ({ ariaDescribedBy, ariaLabelledBy, autoComplete, id, label, name, messageErreurFormulaire, options, required, validation, modifierChoix, valeurInitiale } : ChampListeDeroulanteProps) => {
	const [aEteModifie, setAEteModifie] = useState<boolean>(false)
	const [messageErreur, setMessageErreur] = useState<string | undefined>()
	const messageErreurAffiche = aEteModifie ? messageErreur : messageErreurFormulaire
	
	const onChange = (event: ChangeEvent<HTMLSelectElement>) => {
		modifierChoix(event.target.value)
		setAEteModifie(true)
		if (validation) {
			if (validation.estValide(event.target.value)) {
				setMessageErreur(undefined)
				event.target.setCustomValidity("")
			} else {
				setMessageErreur(validation.messageErreur(event.target.value))
				event.target.setCustomValidity(validation.messageErreur(event.target.value))
			}
		}
	}

	const classesDuGroupe = classNames("fr-input-group", {
		"fr-input-group--error": Boolean(messageErreurAffiche),
	})
	const classesDuSelect = classNames("fr-select", {
		"fr-select--error": Boolean(messageErreurAffiche)
	})

	const autoCompleteProp = autoComplete ? {
		"autoComplete" : autoComplete
	} : ""

	const ariaDescribedByProp = ariaDescribedBy ? {
		"aria-describedby" : id + "-message-erreur " + ariaDescribedBy
	} : {
		"aria-describedby" : id + "-message-erreur"
	}

	const ariaLabelledByProp = ariaLabelledBy ? {
		"aria-labelledby" : ariaLabelledBy
	} : ""


	return (
		<div className={ classesDuGroupe }>
			<div className="fr-select-group">
				{ (label !== "") && (
					<label
						className="fr-label"
						htmlFor={ id }>
						{ label }
					</label>
				) }
				<select
					{ ...ariaDescribedByProp }
					{ ...ariaLabelledByProp }
					aria-required= { required }
					{ ...autoCompleteProp }
					className={ classesDuSelect }
					defaultValue={ valeurInitiale }
					id={ id }
					name={ name }
					onChange={ onChange }
				>
					{ options.map(choix => {
						return (
							<option
								key={ choix.valeur }
								value={ choix.valeur }>
								{ choix.label }
							</option>)
					})  }
				</select>
			</div>
			{ messageErreurAffiche && 
			<p
				className="fr-error-text"
				id={ id + "-message-erreur" }
				role="alert"
			>
				{ messageErreurAffiche }
			</p> }
		</div>
	)
}

export default ChampListeDeroulante
