import ChampsDuFormulaireDemarche from "../../services/GestionnaireDeDemarche/types/ChampsDuFormulaireDemarche"
import GestionnaireAxiosDeDemarche from "../../services/GestionnaireDeDemarche/GestionnaireAxiosDeDemarche"
import { DonnéesRécapitulatif } from "../../services/GestionnaireDeDemarche/types/DonnéesRécapitulatif"
import { ConnectedUser } from "../../services/Authentification/Authentification"

const démarcheValide: ChampsDuFormulaireDemarche = {
	nationalite: "FRA",
	typeDeListe: "Liste principale",
	nomDeFamille: "Stark",
	nomDUsage: "IronMan",
	pasDePrenom: false,
	prenom: "Tony",
	prenom2: undefined,
	prenom3: undefined,
	sexe: "Masculin",
	usagerAUneDateDeNaissanceIncomplète: false,
	dateDeNaissance: "07/05/1990",
	paysDeNaissance: "FRANCE",
	codeInseePaysDeNaissance: "99999",
	communeDeNaissance: "Paris",
	codeInseeCommuneDeNaissance: "75116",
	adresseDeRattachementUsagerRemplitSonAdresseManuellement: false,
	adresseDeRattachementPays: "FRANCE",
	adresseDeRattachementAdresseRedressee: "24 Rue Desaix 75016 PARIS",
	adresseDeRattachementCommune: "PARIS",
	adresseDeRattachementCodePostal: "75016",
	adresseDeRattachementNumero: "24",
	adresseDeRattachementLibelleDeVoie: "Rue Desaix",
	adresseDeRattachementLieuDit: "",
	adresseDeRattachementComplement1Adresse: "",
	adresseDeRattachementComplement2Adresse: "",
	adresseIdentiquePourProgrammeCandidats: "Oui",
	adresseDeContactUsagerRemplitSonAdresseManuellement: false,
	adresseDeContactPays: "FRANCE",
	adresseDeContactAdresseRedressee: "",
	adresseDeContactCommune: "",
	adresseDeContactCodePostal: "",
	adresseDeContactNumero: "",
	adresseDeContactLibelleDeVoie: "",
	adresseDeContactLieuDit: "",
	adresseDeContactComplement1Adresse: "",
	adresseDeContactComplement2Adresse: "",
	lieuDInscription: "Paris",
	codeInseeLieuDInscription: "75000",
	idJustificatifIdentite: "46ef6c88-150a-11eb-8f6b-371c273ffb84",
	nomJustificatifIdentite: "carte_identite_recto.png",
	idJustificatifIdentite2: "46gu6c88-188a-11eb-8f6b-371c273ff888",
	nomJustificatifIdentite2: "carte_identite_verso.png",
	idJustificatifDomicile: "24c86cae-281a-11eb-adc1-0242ac120002",
	nomJustificatifDomicile: "facture_edf_recto.pdf",
	idJustificatifDomicile2: "24c86cae-281a-11eb-adc1-0242ac120003",
	nomJustificatifDomicile2: "facture_edf_verso.pdf",
	idDeclarationEcrite: "",
	nomDeclarationEcrite: "",
	adresseEmail: "",
	numeroTelephone: "06 12 34 56 78",
	choixTypeJustificatif: "identite",
	changementCommune: "oui",
	unSeulFichierPJ: false,
	unSeulFichierPJDomicile: false,
}

function sexeLabelFromCode(sexe: string) {
	switch (sexe) {
	case "male":
		return "Masculin"
	case "female":
		return "Féminin"
	default:
		return ""
	}
}

function formatBirthDate(date: string) {
	if(date) {
		const [year, month, day] = date.split("-")
		return `${day}/${month}/${year}`
	}
	return ""
}

function getValueOrDefault(value: string | undefined | null) {
	return value ?? ""
}

export function créerDémarcheVide(utilisateurCourant?: ConnectedUser): ChampsDuFormulaireDemarche {
	return {
		nationalite: "FRA",
		typeDeListe: "Liste principale",
		nomDeFamille: utilisateurCourant?.authenticationOrigin === "Oidc" ? utilisateurCourant.userInformation["family_name"] : "",
		nomDUsage: utilisateurCourant?.authenticationOrigin === "Oidc" ? getValueOrDefault(utilisateurCourant.userInformation["preferred_username"]) : "",
		sexe: utilisateurCourant?.authenticationOrigin === "Oidc" ? sexeLabelFromCode(utilisateurCourant.userInformation["gender"]) : "",
		pasDePrenom: utilisateurCourant?.authenticationOrigin === "Oidc" && utilisateurCourant.userInformation["given_name"] === "",
		prenom: utilisateurCourant?.authenticationOrigin === "Oidc" ? utilisateurCourant.userInformation["given_name"] : "",
		usagerAUneDateDeNaissanceIncomplète: false,
		dateDeNaissance: utilisateurCourant?.authenticationOrigin === "Oidc" ? formatBirthDate(utilisateurCourant.userInformation["birthdate"]) : "",
		paysDeNaissance: utilisateurCourant?.authenticationOrigin === "Oidc" ? utilisateurCourant.userInformation["birth_country_label"] : "FRANCE",
		codeInseePaysDeNaissance: utilisateurCourant?.authenticationOrigin === "Oidc" ? utilisateurCourant.userInformation["birth_country_code"] : "99999",
		communeDeNaissance: utilisateurCourant?.authenticationOrigin === "Oidc" ? utilisateurCourant.userInformation["birth_place_label"] : "",
		codeInseeCommuneDeNaissance: utilisateurCourant?.authenticationOrigin === "Oidc" ? utilisateurCourant.userInformation["birth_place_code"] : "",
		adresseDeRattachementUsagerRemplitSonAdresseManuellement: false,
		adresseDeRattachementPays: "FRANCE",
		adresseDeRattachementAdresseRedressee: "",
		adresseDeRattachementCommune: "",
		adresseDeRattachementCodePostal: "",
		adresseDeRattachementNumero: "",
		adresseDeRattachementLibelleDeVoie: "",
		adresseDeRattachementLieuDit: "",
		adresseDeRattachementComplement1Adresse: "",
		adresseDeRattachementComplement2Adresse: "",
		adresseIdentiquePourProgrammeCandidats: "Oui",
		adresseDeContactUsagerRemplitSonAdresseManuellement: false,
		adresseDeContactPays: "FRANCE",
		adresseDeContactAdresseRedressee: "",
		adresseDeContactCommune: "",
		adresseDeContactCodePostal: "",
		adresseDeContactNumero: "",
		adresseDeContactLibelleDeVoie: "",
		adresseDeContactLieuDit: "",
		adresseDeContactComplement1Adresse: "",
		adresseDeContactComplement2Adresse: "",
		lieuDInscription: "",
		codeInseeLieuDInscription: "",
		idJustificatifIdentite: "",
		nomJustificatifIdentite: "",
		idJustificatifIdentite2: "",
		nomJustificatifIdentite2: "",
		idJustificatifDomicile: "",
		nomJustificatifDomicile: "",
		idJustificatifDomicile2: "",
		nomJustificatifDomicile2: "",
		idDeclarationEcrite: "",
		nomDeclarationEcrite: "",
		adresseEmail: "",
		numeroTelephone: "",
		choixTypeJustificatif: "",
		unSeulFichierPJ: false,
		unSeulFichierPJDomicile: false,
		changementCommune: ""
	}
}

export function créerDémarcheValide(): ChampsDuFormulaireDemarche {
	return { ...démarcheValide }
}

export function créerDémarche(valeursSurchargées: Partial<ChampsDuFormulaireDemarche>): ChampsDuFormulaireDemarche {
	return {
		...créerDémarcheValide(),
		...valeursSurchargées
	}
}

export function créerRecapitulatif(demarche: ChampsDuFormulaireDemarche): DonnéesRécapitulatif {
	return GestionnaireAxiosDeDemarche().générerRécapitulatif(demarche)
}
