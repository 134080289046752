import React, { useEffect } from "react"

const DonnerSonAvis: React.FunctionComponent = () => {

	useEffect(() => {
		document.title = "Donnez votre avis sur votre demande d’inscription sur les listes électorales - service-public.fr"
	}, [])

	return (
		<div className="sp-demarche-step">
			<h2 className="sp-demarche-step-titre">
				<span className="sp-demarche-step-titre-nombre">
					<span
						aria-hidden="true"
						className="fr-icon-edit-line">
					</span>
				</span>
				<span className="sp-demarche-step-titre-label">Donnez-nous votre avis</span>
			</h2>
			<p>
				Vous venez de réaliser une <strong>démarche administrative en ligne</strong>.
			</p>
			<p>
				<strong>Partagez</strong> votre expérience
				et <strong>aidez-nous</strong> à <strong>améliorer</strong> ce <strong>service public </strong>
				numérique <strong>gratuit</strong>.
			</p>
			<p className="dile-text--center fr-mt-4w fr-mb-4w">
				<a
					className="sp-btn-avis"
					href="https://voxusagers.numerique.gouv.fr/Demarches/1220?&view-mode=formulaire-avis&nd_mode=en-ligne-entièrement&nd_source=button&key=5eee44cf2c000ff9a1c019e95d1273d6"
					rel="noopener noreferrer"
					target="_blank"
					title="Je donne mon avis sur cette démarche - Services-publics + - voxusagers.numerique.gouv.fr - nouvelle fenêtre">
					<img
						alt="Je donne mon avis sur cette démarche - Services-publics + - voxusagers.numerique.gouv.fr - nouvelle fenêtre"
						src="btn-donner-son-avis.svg"/>
				</a>
			</p>
		</div>
	)
}

export default DonnerSonAvis
