import React from "react"

const PiedDePage: React.FunctionComponent = () => {
	return (
		<div className="fr-footer__body">
			<div className="fr-footer__brand fr-enlarge-link">
				<a 
					href="https://www.service-public.fr/" 
					title="Accueil Service-Public.fr">
					<p className="fr-logo">République <br />Française</p>
				</a>
			</div>
			<div className="fr-footer__content">
				<p className="fr-footer__content-desc">
					Service Public vous informe et vous oriente vers les services qui permettent de connaître vos obligations, d’exercer vos droits et de faire vos démarches du quotidien. <br />
					<span>Il est édité par la </span>
					<a 
						href="https://www.dila.premier-ministre.gouv.fr/"
						rel="noopener noreferrer external" 
						target="_blank" 
						title="Direction de l'information légale et administrative - Nouvelle fenêtre">
							Direction de l’information légale et administrative
					</a>
					<span> et réalisé en partenariat avec les administrations nationales et locales.</span>
				</p>
				<ul className="fr-footer__content-list">
					<li className="fr-footer__content-item">
						<a 
							className="fr-footer__content-link" 
							href="http://legifrance.gouv.fr"
							rel="noopener noreferrer external"
							target="_blank"
							title="legifrance.gouv.fr - nouvelle fenêtre">
							legifrance.gouv.fr
						</a>
					</li>
					<li className="fr-footer__content-item">
						<a 
							className="fr-footer__content-link" 
							href="http://gouvernement.fr"
							rel="noopener noreferrer external" 
							target="_blank" 
							title="gouvernement.fr - nouvelle fenêtre">
								gouvernement.fr
						</a>
					</li>
					<li className="fr-footer__content-item">
						<a 
							className="fr-footer__content-link" 
							href="http://service-public.fr"
							rel="noopener noreferrer external" 
							target="_blank" 
							title="service-public.fr - nouvelle fenêtre">
							service-public.fr
						</a>
					</li>
					<li className="fr-footer__content-item">
						<a 
							className="fr-footer__content-link" 
							href="http://data.gouv.fr"
							rel="noopener noreferrer external"
							target="_blank" 
							title="data.gouv.fr - nouvelle fenêtre">
							data.gouv.fr
						</a>
					</li>
				</ul>
			</div>
		</div>
	)
}

export default PiedDePage
