import React from "react"

const BasPiedDePage: React.FunctionComponent = () => {
	return (
		<div className="fr-footer__bottom">
			<ul className="fr-footer__bottom-list">
				<li className="fr-footer__bottom-item">
					<a 
						className="fr-footer__bottom-link" 
						href="https://www.service-public.fr/P10003">
							Plan du site
					</a>
				</li>
				<li className="fr-footer__bottom-item">
					<a
						className="fr-footer__bottom-link"
						href=" https://www.service-public.fr/P10000">
						Accessibilité : partiellement conforme
					</a>
				</li>
				<li className="fr-footer__bottom-item">
					<a
						className="fr-footer__bottom-link"
						href="https://www.service-public.fr/P10125">
						Accessibilité des services en ligne
					</a>
				</li>
				<li className="fr-footer__bottom-item">
					<a 
						className="fr-footer__bottom-link" 
						href="https://www.service-public.fr/P10025">
						Mentions légales
					</a>
				</li>
				<li className="fr-footer__bottom-item">
					<a
						className="fr-footer__bottom-link"
						href="https://www.service-public.fr/P10001">
						Données personnelles et sécurité
					</a>
				</li>
				<li className="fr-footer__bottom-item">
					<a 
						className="fr-footer__bottom-link" 
						href="https://www.service-public.fr/P10050">
						Conditions générales d’utilisation
					</a>
				</li>
			</ul>
			<div className="fr-footer__bottom-copy">
				<p>
					<span>Sauf mention explicite de propriété intellectuelle détenue par des tiers, les contenus de ce site sont proposés sous </span>
					<a 
						href="https://github.com/etalab/licence-ouverte/blob/master/LO.md"
						rel="noopener noreferrer external" 
						target="_blank" 
						title="licence etalab-2.0 - nouvelle fenêtre"
					>
						licence etalab-2.0
					</a>
				</p>
			</div>
		</div>
	)
}

export default BasPiedDePage
