import React, { ChangeEvent } from "react"
import ChampTextuel from "../../ui/ChampTextuel/ChampTextuel"
import Complement1AdresseValidation
	from "../../../services/ValidateurDeDemarche/validateursParChamp/Complement1AdresseValidation"
import Complement2AdresseValidation
	from "../../../services/ValidateurDeDemarche/validateursParChamp/Complement2AdresseValidation"

type ChampComplementAdresseProps = {
	complement1Adresse: string
	complement2Adresse: string
	messageErreurFormulaireComplement1Adresse?: string
	messageErreurFormulaireComplement2Adresse?: string
	onChange: (event: ChangeEvent<HTMLInputElement>) => void
	pays: string
	suffixe: string
}

const ChampComplementAdresse
	: React.FunctionComponent<ChampComplementAdresseProps> = ({ complement1Adresse, complement2Adresse, messageErreurFormulaireComplement1Adresse, messageErreurFormulaireComplement2Adresse, onChange, suffixe, pays } : ChampComplementAdresseProps) => {
		return (
			<div className="fr-form-group fr-mt-2w">
				<fieldset className="fr-fieldset contents-display">
					<legend className="fr-fieldset__legend  fr-text--regular">
						Vous pouvez ajouter un <strong>complément d’adresse</strong>
					</legend>
					<ChampTextuel
						autoComplete={ "off" }
						id={ suffixe + "Complement2Adresse" }
						label="Bâtiment, immeuble"
						maxLength={ 50 }
						messageErreurFormulaire={ messageErreurFormulaireComplement2Adresse }
						name={ suffixe + "Complement2Adresse" }
						onChange={ onChange }
						texteAide={ pays === "FRANCE" ? "Facultatif - Exemple : Bâtiment C" : "Facultatif - Exemple : Building 1" }
						valeur={ complement2Adresse }
						validation={ new Complement2AdresseValidation() }
					/>
					<ChampTextuel
						autoComplete={ "off" }
						id={ suffixe + "Complement1Adresse" }
						label="Appartement, étage, escalier, “chez”"
						maxLength={ 50 }
						messageErreurFormulaire={ messageErreurFormulaireComplement1Adresse }
						name={ suffixe + "Complement1Adresse" }
						onChange={ onChange }
						texteAide={ pays === "FRANCE" ? "Facultatif - Exemple : Appartement 113" : "Facultatif - Exemple : c/o Agatha Miller" }
						valeur={ complement1Adresse }
						validation={ new Complement1AdresseValidation() }
					/>
				</fieldset>
			</div>
		)
	}

export default ChampComplementAdresse

