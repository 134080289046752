import axios from "../Axios/AxiosApi"
import { BACKEND_URL } from "../configurationHttp"

export interface BaseResponse<T> { ok: boolean, data?: T }
export interface ConnectedUser { sub: string, email: string, userInformation: { [key: string]: string }, authenticationOrigin: string }
export type AuthMeResponse = BaseResponse<ConnectedUser>;

export function recupereInfoUtilisateur(): Promise<AuthMeResponse> {
	return axios.get(BACKEND_URL + "/authn/me").then(e => e.data)
}
