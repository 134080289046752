import React, { ChangeEvent } from "react"
import classNames from "classnames"

export type BoutonRadioProps = {
	checked?: boolean,
	valeur: string,
	id: string,
	idMessageErreur?: string,
	label: string,
	nom: string,
	messageChangementDeContexte?: string
	required?: boolean,
	onChange: (event: ChangeEvent<HTMLInputElement>) => void
	disabled?: boolean
}

const BoutonRadio: React.FunctionComponent<BoutonRadioProps> = ({ checked = false, valeur, label, nom, messageChangementDeContexte, id, idMessageErreur, required = false, onChange, disabled = false }: BoutonRadioProps) => {

	const idMessageErreur2 = idMessageErreur ? idMessageErreur : ""

	const descriptionBoutonRadio = classNames( {
		[id + "-message-avertissement"]: messageChangementDeContexte,
		[idMessageErreur2] : idMessageErreur2 != ""
	})
	const ariaDescribedbyProp = descriptionBoutonRadio ? {
		"aria-describedby" : descriptionBoutonRadio
	} : ""

	return (
		<div className="fr-radio-group">
			<input
				aria-checked={ checked }
				{ ...ariaDescribedbyProp }
				checked={ checked }
				disabled={ disabled }
				id={ id }
				name={ nom }
				onChange={ onChange }
				required= { required }
				type="radio"
				value={ valeur }
			/>
			<label
				className="fr-label"
				htmlFor={ id }>
				{ label }				
			</label>
			{ messageChangementDeContexte &&
				<p 
					className="fr-sr-only" 
					id={ id + "-message-avertissement" } >
					{ messageChangementDeContexte }
				</p>
			}
		</div>
	)
}

export default BoutonRadio
