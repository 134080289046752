import React, { ReactNode, useContext, useState } from "react"
import {  useUtilisateurCourant } from "../Authentifié"
import ChampsDuFormulaireDemarche from "../../../services/GestionnaireDeDemarche/types/ChampsDuFormulaireDemarche"
import { GestionnaireDeDemarcheContext } from "../../App"
import Cookies from "universal-cookie"
import { useHistory } from "react-router-dom"
import { créerDémarcheVide } from "../../../test/fixtures/demarche"
import { DemarcheContexte } from "./DemarcheContext"

type DemarcheProps = {
	children: ReactNode,
}

const Demarche: React.FunctionComponent<DemarcheProps> = ({ children }: DemarcheProps) => {
	const history = useHistory()
	const utilisateurCourant = useUtilisateurCourant()
	const idUsager = utilisateurCourant.sub

	const { envoyer, générerRécapitulatif } = useContext(GestionnaireDeDemarcheContext)

	const [valeursFormulaireEnregistrées, setValeursFormulaireEnregistrées] = useState<ChampsDuFormulaireDemarche>(créerDémarcheVide(utilisateurCourant))
	const [messageErreurEnvoi, setMessageErreurEnvoi] = useState<string>("")
	const [champAFocaliser, setChampAFocaliser] = useState<string>("")
	const [envoiEnCours, setEnvoiEnCours] = useState<boolean>(false)

	const sauvegarderDonnéesValides = (valeurs: ChampsDuFormulaireDemarche) => {
		setValeursFormulaireEnregistrées(valeurs)
		history.push("/recapitulatif")
	}

	const envoyerLaDemarche = async () => {
		if (valeursFormulaireEnregistrées) {
			try {
				setEnvoiEnCours(true)
				const informationTeledossier = await envoyer(idUsager, valeursFormulaireEnregistrées)
				const cookies = new Cookies()
				cookies.set("numeroTeledossier", informationTeledossier.data.numeroTeledossier)
				cookies.set("dateHeureDepot", informationTeledossier.data.dateHeureDepot)
				setEnvoiEnCours(false)
				history.push("/confirmation", JSON.stringify(générerRécapitulatif(valeursFormulaireEnregistrées)))
			} catch (erreur) {
				setMessageErreurEnvoi(erreur.message)
				setEnvoiEnCours(false)
			}
		}
	}

	const modifierLePremierBloc = async () => {
		setChampAFocaliser("nomDeFamille")
		history.push("/demarche")
	}

	const modifierLeDeuxiemeBloc = async () => {
		setChampAFocaliser("lieuDInscription")
		history.push("/demarche")
	}

	const modifierLeTroisiemeBloc = async () => {
		setChampAFocaliser("adresseEmail")
		history.push("/demarche")
	}

	const revoirLaConfirmation = () => {
		générerRécapitulatif(valeursFormulaireEnregistrées)
		history.push("/confirmation", JSON.stringify(générerRécapitulatif(valeursFormulaireEnregistrées)))
	}

	return (
		<DemarcheContexte.Provider
			value={ {
				valeursFormulaireEnregistrées,
				champAFocaliser,
				setChampAFocaliser,
				sauvegarderDonnéesValides,
				recapitulatif: générerRécapitulatif(valeursFormulaireEnregistrées),
				modifierLePremierBloc,
				modifierLeDeuxiemeBloc,
				modifierLeTroisiemeBloc,
				envoyerLaDemarche,
				messageErreurEnvoi,
				envoiEnCours,
				revoirLaConfirmation
			} }
		>
			{ children }
		</DemarcheContexte.Provider>
	)
}

export default Demarche
