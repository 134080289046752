import React, { Fragment, SyntheticEvent, useContext, useEffect, useLayoutEffect, useRef, useState } from "react"
import MessageChargement from "../../ui/MessageChargement/MessageChargement"
import MessagesErreursValidation
	from "../../../services/ValidateurDeDemarche/validateursParChamp/MessagesErreursValidation"
import convertSize from "convert-size"
import { DepotDePiecesJustificativesContext } from "../../App"
import Validation from "../../../services/ValidateurDeDemarche/validateursParChamp/Validation"
import "./PieceJustificative.css"

export type PieceJustificativeProps =
	{
		cacher: boolean
		idPieceJustificative?: string
		idUsager: string
		label: string
		messageErreurFormulaire?: string | null
		name: string
		nomPieceJustificative?: string | null
		onChange: (idPieceJustificative: string, nomPieceJustificative: string) => void
		onDelete: () => void
		position: string
		required: boolean
		titre: string
		typePieceJustificative: string
		validation?: Validation
		unSeulFichierPJ?: boolean
	}

const TAILLE_MAXIMALE_PIECE_JUSTIFICATIVE_EN_BYTES = convertSize("2 MiB")
const TAILLE_MINIMALE_PIECE_JUSTIFICATIVE_EN_BYTES = convertSize("0 MiB")

const PieceJustificative: React.FunctionComponent<PieceJustificativeProps> = (
	{
		idPieceJustificative: idPieceJustificativeInitial,
		idUsager,
		label,
		messageErreurFormulaire,
		name,
		nomPieceJustificative,
		onChange,
		onDelete,
		position,
		required,
		titre,
		typePieceJustificative,
		unSeulFichierPJ
	}: PieceJustificativeProps) => {

	const [messageErreurTeleversement, setMessageErreurTeleversement] = useState<string>("")
	const [idPieceJustificativeActuel, setIdPieceJustificativeActuel] = useState<string>("")
	const [erreurTeleversement, setErreurTeleversement] = useState<boolean>(false)
	const [aEteModifié, setAEteModifié] = useState<boolean>(false)
	const [envoiEnCours, setEnvoiEnCours] = useState<boolean>(false)

	const { televerser, supprimer } = useContext(DepotDePiecesJustificativesContext)

	const [champsAFocus, setChampAFocus] = useState<"" | "refInputFile" | "refButton">("")
	const refInputFile = useRef<HTMLInputElement>(null)
	const refButton = useRef<HTMLButtonElement>(null)

	const messageErreurAffiche = aEteModifié ? messageErreurTeleversement : messageErreurFormulaire
	const idPieceJustificativePresent = aEteModifié ? idPieceJustificativeActuel : idPieceJustificativeInitial

	useEffect(() => {
		setAEteModifié(false)
	}, [messageErreurFormulaire])

	useLayoutEffect(() => {
		if (champsAFocus === "refInputFile") {
			if (refInputFile && refInputFile.current) {
				refInputFile.current.focus()
				setChampAFocus("")
			}
		}
		if (champsAFocus === "refButton") {
			if (refButton && refButton.current) {
				refButton.current.focus()
				setChampAFocus("")
			}
		}
	}, [champsAFocus])

	const onFileInputChange = (event: any) => {
		const pieceJustificative = event.target.files[0]
		setAEteModifié(true)

		if (!pieceJustificative) {
			setMessageErreurTeleversement("")
		} else {
			const nomPieceJustificative = event.target.files[0].name
			if(pieceJustificative && pieceJustificative.size > TAILLE_MINIMALE_PIECE_JUSTIFICATIVE_EN_BYTES) {
				if (pieceJustificative && pieceJustificative.size <= TAILLE_MAXIMALE_PIECE_JUSTIFICATIVE_EN_BYTES) {
					setEnvoiEnCours(true)
					televerser(idUsager, typePieceJustificative, pieceJustificative, position)
						.then((idPieceJustificative) => {
							setMessageErreurTeleversement("")
							setIdPieceJustificativeActuel(idPieceJustificative)
							if (onChange) {
								onChange(idPieceJustificative, nomPieceJustificative)
								setChampAFocus("refButton")
							}
						})
						.catch((error) => {
							if (error.response.status == 401) {
								setMessageErreurTeleversement("Vous n'êtes pas connecté(e)")
							} else {
								setMessageErreurTeleversement(MessagesErreursValidation.PIECE_JUSTIFICATIVE_MAUVAIS_FORMAT)
							}
							setErreurTeleversement(true)
						})
						.finally(() => {
							setEnvoiEnCours(false)
						})
				} else {
					setMessageErreurTeleversement(MessagesErreursValidation.PIECE_JUSTIFICATIVE_VOLUMINEUSE)
					setErreurTeleversement(true)
				}
			} else  {
				setMessageErreurTeleversement(MessagesErreursValidation.PIECE_JUSTIFICATIVE_VIDE)
				setErreurTeleversement(true)
			}
		}
	}

	const onClick = async (event: SyntheticEvent) => {
		event.preventDefault()
		setAEteModifié(true)
		try {
			await supprimer(idUsager, idPieceJustificativeInitial)
			if (refInputFile.current) {
				refInputFile.current.value = ""
			}
			onDelete()
			setIdPieceJustificativeActuel("")
			setChampAFocus("refInputFile")
		} catch {
			setMessageErreurTeleversement(`Le document ${nomPieceJustificative} n'a pas été supprimé.`)
		}
	}

	return (
		<Fragment>
			{ !unSeulFichierPJ && (
				<p
					id={ "titre-" + name }>
					<strong>{ titre }</strong>
				</p>
			) }
			{ !idPieceJustificativePresent && (
				<p
					className="fr-hint-text"
					id={ "format-valide-" + name }
				>
					{ !required && (
						<span className={ "piece_"  + typePieceJustificative + position }></span>
					) }
					Formats acceptés : PDF, JPG, JPEG, PNG. <br/>Taille du fichier : 2 Mo maximum
				</p>
			) }
			{ messageErreurAffiche && 
			<p
				className="fr-error-text"
				id={ "etat-televersement-" + name }
				role="alert"
			>
				{ messageErreurAffiche }
			</p> }
			<div className="sp-group-btn-upload">
				{ idPieceJustificativePresent && (
					<div className="sp-btn-upload sp-btn-upload--is-upload">
						<div className="sp-input-group">
							<p className="sp-file-name">
								{ nomPieceJustificative }
								<span className="fr-sr-only"> a été ajouté</span>
							</p>
							<button
								className="btn-in-input"
								onClick={ onClick }
								ref={ refButton }
								title={ "Supprimer le fichier " + nomPieceJustificative }
								type="button"
							>
								<span
									aria-hidden="true"
									className="fr-fi-close-line"
								/>
								<span className="fr-sr-only">Supprimer le fichier</span>
							</button>
						</div>
					</div>
				) }

				{ !idPieceJustificativePresent && envoiEnCours && (
					<div className="sp-btn-upload sp-btn-upload--is-upload">
						<MessageChargement texte="Transmission en cours" />
					</div>
				) }
				{ !idPieceJustificativePresent && !envoiEnCours && (
					<div className="sp-btn-upload">
						<input
							accept=".jpg,.jpeg,.pdf,.png"
							aria-describedby={
								(unSeulFichierPJ ? "" : ("titre-" + name + " ")) +
								"format-valide-" + name + " " +
								"etat-televersement-" + name
							}
							aria-invalid={ erreurTeleversement || Boolean(messageErreurAffiche) }
							aria-required={ required }
							className="fr-input fr-sr-only"
							id={ `piece-justificative-${name}` }
							name={ name }
							onChange={ onFileInputChange }
							ref={ refInputFile }
							type="file"
						/>
						<label
							className="fr-label"
							htmlFor={ `piece-justificative-${name}` }
						>
							<span
								aria-hidden="false"
								className="fr-icon-file-add-line"
							/>
							{ label }
						</label>
					</div>
				) }
			</div>
		</Fragment>
	)
}

export default PieceJustificative
