import React  from "react"

type CaseACocherProps = {
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
	id: string
	valeur: string
	label: string
	texteAide: string
	texteAvertissementDeLaMiseAJour?: string
	caseEstCochée: boolean
	texteAvertissementPréalable?: string
	disabled?: boolean
}

const CaseACocher : React.FunctionComponent<CaseACocherProps> = ({ onChange, id, valeur, label, texteAide, texteAvertissementDeLaMiseAJour, caseEstCochée, texteAvertissementPréalable, disabled = false }: CaseACocherProps)=> {
	return(
		<div className="fr-checkbox-group fr-checkbox-group--sm">
			{ (texteAvertissementDeLaMiseAJour !== "") && (
				<p
					aria-atomic="true"
					aria-live="assertive"
					className="fr-sr-only">
					{ texteAvertissementDeLaMiseAJour }
				</p>
			) }
			<input
				aria-checked={ caseEstCochée }
				checked={ caseEstCochée }
				disabled={ disabled }
				id={ id }
				onChange={ onChange }
				type="checkbox"
				value={ valeur }
			/>
			<label
				className="fr-label"
				htmlFor={ id }>
				{ label }
				<span className="fr-hint-text">{ texteAide }</span>
				{ texteAvertissementPréalable &&
					<span className="fr-sr-only">
						{ texteAvertissementPréalable }
					</span>
				}
			</label>
		</div>
	)
}

export default CaseACocher
