import React, { SyntheticEvent, useState } from "react"
import { Fragment, useLayoutEffect, useRef } from "react"
import PrenomValidation from "../../../services/ValidateurDeDemarche/validateursParChamp/PrenomValidation"
import PrenomSecondaireValidation from "../../../services/ValidateurDeDemarche/validateursParChamp/PrenomSecondaireValidation"
import ChampTextuelAvecRef from "../../ui/ChampTextuel/ChampTextuelAvecRef"
import CaseACocher from "../../ui/CaseACocher/CaseACocher"

type Prenoms = {
	pasDePrenom: boolean,
	prenom?: string,
	prenom2?: string,
	prenom3?: string
}

type ChampsPrenomsProps = {
	erreursPrenomsFormulaireDemarche: Prenoms,
	valeursPrenomsInitiales: Prenoms,
	mettreAJourLesValeursDesPrenoms: (prenoms : Partial<Prenoms>) => void,
	disabled?: boolean
}

const ChampsPrenoms: React.FunctionComponent<ChampsPrenomsProps> = ({ erreursPrenomsFormulaireDemarche, valeursPrenomsInitiales, mettreAJourLesValeursDesPrenoms, disabled = false }: ChampsPrenomsProps) => {
	const champPrenom1 = useRef<HTMLInputElement>(null)
	const champPrenom2 = useRef<HTMLInputElement>(null)
	const champPrenom3 = useRef<HTMLInputElement>(null)
	const [champsAFocus, setChampAFocus] = useState<string>("")

	const [valeursPrenomsModifiees, setValeursPrenomsModifiees] = useState<Prenoms>({ pasDePrenom: false })

	const valeurAfficheePasDePrenom = valeursPrenomsModifiees.pasDePrenom ? valeursPrenomsModifiees.pasDePrenom : valeursPrenomsInitiales.pasDePrenom
	const valeurAfficheePrenom = valeursPrenomsModifiees.prenom ? valeursPrenomsModifiees.prenom : valeursPrenomsInitiales.prenom
	const valeurAfficheePrenom2 = valeursPrenomsModifiees.prenom2 ? valeursPrenomsModifiees.prenom2 : valeursPrenomsInitiales.prenom2
	const valeurAfficheePrenom3 = valeursPrenomsModifiees.prenom3 ? valeursPrenomsModifiees.prenom3 : valeursPrenomsInitiales.prenom3

	const usagerAUnPrenom2 = valeursPrenomsModifiees.prenom2 !== undefined || valeursPrenomsInitiales.prenom2 !== undefined
	const usagerAUnPrenom3 = valeursPrenomsModifiees.prenom3 !== undefined || valeursPrenomsInitiales.prenom3 !== undefined

	const afficherLaCroixPrenom2 = Boolean(usagerAUnPrenom2 && !usagerAUnPrenom3)
	const afficherLaCroixPrenom3 = Boolean(usagerAUnPrenom3)

	const mettreAJourLeChampPrenom = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.getAttribute("name")) {
			const nomChamps = event.target.getAttribute("name") || ""
			setValeursPrenomsModifiees({ ...valeursPrenomsModifiees, [nomChamps]: event.target.value })
			mettreAJourLesValeursDesPrenoms( { [nomChamps]: "" } )
		}
	}

	const onClickSurLeChampPasDePrenom = (event: React.ChangeEvent<HTMLInputElement>) => {
		const laCaseEstCochee = event.target.checked
		setValeursPrenomsModifiees({ ...valeursPrenomsModifiees, pasDePrenom: laCaseEstCochee, prenom: "", prenom2: undefined, prenom3: undefined })
		mettreAJourLesValeursDesPrenoms( { pasDePrenom: laCaseEstCochee, prenom: "", prenom2: undefined, prenom3: undefined } )

	}

	const informerMiseAJourDePrenoms = () => {
		mettreAJourLesValeursDesPrenoms( {
			pasDePrenom: valeurAfficheePasDePrenom,
			prenom: valeurAfficheePrenom,
			prenom2: valeurAfficheePrenom2,
			prenom3: valeurAfficheePrenom3
		} )
	}

	const ajouterUnPrenom = (event: SyntheticEvent) => {
		event.preventDefault()
		if (usagerAUnPrenom2) {
			setValeursPrenomsModifiees({ ...valeursPrenomsModifiees, prenom3: "" })
			setChampAFocus("champPrenom3")
			mettreAJourLesValeursDesPrenoms( { prenom3: "" } )
		} else {
			setValeursPrenomsModifiees({ ...valeursPrenomsModifiees, prenom2: "" })
			setChampAFocus("champPrenom2")
			mettreAJourLesValeursDesPrenoms( { prenom2: "" } )
		}
	}
	const supprimerPrenom2 = (event: SyntheticEvent) => {
		event.preventDefault()
		setChampAFocus("champPrenom1")
		setValeursPrenomsModifiees({ ...valeursPrenomsModifiees, prenom2: undefined })
		mettreAJourLesValeursDesPrenoms( { prenom2: undefined } )
	}
	const supprimerPrenom3 = (event: SyntheticEvent) => {
		event.preventDefault()
		setChampAFocus("champPrenom2")
		setValeursPrenomsModifiees({ ...valeursPrenomsModifiees, prenom3: undefined })
		mettreAJourLesValeursDesPrenoms( { prenom3: undefined } )
	}
	useLayoutEffect(() => {
		if (champsAFocus === "champPrenom1") {
			if(champPrenom1 && champPrenom1.current) {
				champPrenom1.current.focus()
				setChampAFocus("")
			}
		}
		if (champsAFocus === "champPrenom2") {
			if(champPrenom2 && champPrenom2.current) {
				champPrenom2.current.focus()
				setChampAFocus("")
			}
		}
		if (champsAFocus === "champPrenom3") {
			if(champPrenom3 && champPrenom3.current) {
				champPrenom3.current.focus()
				setChampAFocus("")
			}
		}
	}, [champsAFocus])

	return (
		<Fragment>
			<h3 className="fr-text--lg fr-mb-1w fr-mt-6w">Quels sont vos prénoms ?</h3>
			<p
				id={ "description-prenom" }
			>
				Indiquez <strong>{ "tous vos prénoms dans l'ordre" }</strong> comme sur votre
				acte de naissance
			</p>
			<CaseACocher
				caseEstCochée={ valeurAfficheePasDePrenom }
				disabled={ disabled }
				id="no-prenom"
				label="Je n’ai pas de prénom"
				onChange={ onClickSurLeChampPasDePrenom }
				texteAide={ valeurAfficheePasDePrenom ? "Décochez si vous n’êtes pas concerné" : "Cochez si vous êtes concerné" }
				valeur="no-prenom"
			/>
			{ !valeurAfficheePasDePrenom &&
			<Fragment>
				<ChampTextuelAvecRef
					autoComplete="given-name"
					descriptionId={ "description-prenom" }
					disabled={ disabled }
					id={ "prenom" }
					label="Prénom 1"
					messageErreurFormulaire={ erreursPrenomsFormulaireDemarche.prenom }
					name={ "prenom" }
					onBlur={ informerMiseAJourDePrenoms }
					onChange={ mettreAJourLeChampPrenom }
					ref={ champPrenom1 }
					valeur={ valeurAfficheePrenom }
					validation={ new PrenomValidation() }
				/>
				{ usagerAUnPrenom2 &&
					<ChampTextuelAvecRef
						afficherBoutonSuppression={ afficherLaCroixPrenom2 }
						autoComplete="additional-name"
						id={ "prenom2" }
						label="Prénom 2"
						messageErreurFormulaire={ erreursPrenomsFormulaireDemarche.prenom2 }
						name={ "prenom2" }
						onBlur={ informerMiseAJourDePrenoms }
						onChange={ mettreAJourLeChampPrenom }
						onClickBoutonSuppression={ supprimerPrenom2 }
						ref={ champPrenom2 }
						texteBoutonSuppression="Supprimer prénom 2"
						valeur={ valeurAfficheePrenom2 }
						validation={ new PrenomSecondaireValidation() }
					/>
				}
				{	usagerAUnPrenom3 &&
					<ChampTextuelAvecRef
						afficherBoutonSuppression={ afficherLaCroixPrenom3 }
						autoComplete="additional-name"
						id={ "prenom3" }
						label="Prénom 3 et suivants"
						messageErreurFormulaire={ erreursPrenomsFormulaireDemarche.prenom3 }
						name={ "prenom3" }
						onBlur={ informerMiseAJourDePrenoms }
						onChange={ mettreAJourLeChampPrenom }
						onClickBoutonSuppression={ supprimerPrenom3 }
						ref={ champPrenom3 }
						texteAide="Écrivez vos prénoms séparés par un espace"
						texteBoutonSuppression="Supprimer prénom 3"
						valeur={ valeurAfficheePrenom3 }
						validation={ new PrenomSecondaireValidation() }
					/>
				}
				{	!usagerAUnPrenom3 && !disabled &&
					<button
						className="fr-btn fr-btn--secondary"
						onClick={ ajouterUnPrenom }
						title="Ajouter un prénom"
						type="button"
					>
						<span
							aria-hidden="true"
							className="fr-icon-add-line">
						</span>
					Ajouter un prénom
					</button>
				}
			</Fragment>
			}
		</Fragment>
	)
}

export default ChampsPrenoms
